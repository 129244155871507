import React,{useContext,useEffect,useState} from 'react'
import {Form,Input,Button,Select,message} from "antd";
import HrDashboard from '../Dashboard/HRDashboard';
import { AuthContext } from '../../contexts/AuthContext';


const AddJD = () => {
    const [form]=Form.useForm();
    const { backendUrl } = useContext(AuthContext);
    const [employees,setEmployees]=useState([]);
    useEffect(()=>{
      getEmployees();
    },[])

    const onFinish=async(values)=>{
      console.log(values)
      const response=await fetch(`${backendUrl}/add-jd/`,{
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization':'Token '+localStorage.getItem('token')
        },
        body:JSON.stringify(values)
      });
      if(response.ok){
        const data= await response.json();
        message.success(data.success)
        form.resetFields();
      }
    }
const getEmployees=async()=>{
  const response=await fetch(`${backendUrl}/get-employees/`,{
    method:'GET',
    headers:{
      'Content-Type':'application/json',
      'Authorization':'Token '+localStorage.getItem('token')
    }

  });
  if(response.ok){

    const data=await response.json();
    setEmployees(data);
  }
  
 
}

  return (
    <HrDashboard>
     <div><h1>Add Job Description and KRA's</h1></div>
     <Form
     form={form}
     onFinish={onFinish}>
      <Form.Item name="id">
        <Select label="Employee" name="employeeId">
          {employees.map(employee=>(
            <Select.Option key={employee.employee_id} value={employee.employee_id}>{employee.username} {employee.lastName}</Select.Option>
          ))}
        </Select>
      </Form.Item>
        <Form.Item label="Job Description" name="jd">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="KRA's" name="kras">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>

        
     </Form>
    </HrDashboard>
   

  )
}

export default AddJD