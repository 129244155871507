import React, { useState, useEffect, useContext } from "react";
import { Button } from "../ui/Button";
import { Card, CardContent, CardHeader } from "../ui/Card";
import UserCredentials from "./UserCredentials";
import UserProfile from "./UserProfile";
import { api } from "../../lib/axios";
import { useNavigate } from "react-router-dom";
import { Loader2, MoveLeft, MoveRight } from "lucide-react";
import { AuthContext } from "../../contexts/AuthContext";
import {message,Select} from "antd";

const CreateUser = () => {
  const [usercred, setUserCred] = useState(true);
  const [userprofile, setUserProfile] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    employee_id: "",
    designation: "",
    department: "",
    type: "",
    gender: "",
    phone_number: "",
    reported_to: "",
    date_of_joining: "",
    ctc: "",
    role: "",
  });
  const [formValues, setFormValues] = useState({});
  const [goals, setGoals] = useState([{}]);
  const [parameters, setParameters] = useState({
    primary: [],
    secondary: [],
  });
  const [submitError, setSubmitError] = useState();
  const [loading, setLoading] = useState(false);
  const [reportToUsers, setReportToUsers] = useState([]);
  const { backendUrl } = useContext(AuthContext);
  const token=localStorage.getItem('token');
  const navigate = useNavigate();
  console.log(backendUrl,"url","token",token)

  useEffect(() => {
    getReportToUsers();
    getGoals();
  }, []);

  const getGoals = async () => {
    const initialFormValues = {
      primary: {},
      secondary: {},
    };
  
    try {
      const response = await fetch(`${backendUrl}/get-goal-parameters/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch goal parameters");
      }
  
      const data = await response.json();
  
      const primaryParameters = data.primaryParameters;
      const secondaryParameters = data.secondaryParameters;
  
      primaryParameters.forEach((param) => {
        initialFormValues.primary[param.name] = {
          monthly: "",
          quarterly: "",
          half_yearly: "",
          annually: "",
        };
      });
  
      secondaryParameters.forEach((param) => {
        initialFormValues.secondary[param.name] = {
          monthly: "",
          quarterly: "",
          half_yearly: "",
          annually: "",
        };
      });
  
      setFormValues(initialFormValues);
      setParameters({
        primary: primaryParameters,
        secondary: secondaryParameters,
      });
    } catch (error) {
      console.log(error);
    }
  };
  

  const getReportToUsers = async () => {
    console.log("token: " + token);
    const response = await fetch(`${backendUrl}/get-report-to-list/1,2/`, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      
    });
    const data = await response.json();
    if (response.status === 200) {
      setReportToUsers(data);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError([]);
    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/register/`, {
        method: "POST",  // This should be at the top level
        headers: {
          Authorization: `Token ${token}`,  // Authorization header with Bearer token
          "Content-Type": "application/json",  // Content-Type header for JSON data
        },
        body: JSON.stringify({
          ...formData,
          formValues,  // Including formValues in the request body
        }),
      });
  
      if (response.status === 201) {
        message.success()
        message.success("User Created Successfully");
        navigate("/dashboard");
      } else {
        const errorData = await response.json();  // Parse error response as JSON
        setSubmitError(JSON.stringify(errorData.message));  // Display error message
      }
  
      console.log(response);
    } catch (error) {
      console.log(error);
      setSubmitError("An error occurred while submitting the form.");  // General error message
    }
  
    setLoading(false);
  };
  

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <Card className="relative flex flex-col transition-all">
        <CardHeader>
          <span className="text-center text-2xl font-bold">Create User</span>
        </CardHeader>
        <CardContent className="mb-20">
          <form
            onSubmit={handleSubmit}
            className="flex min-h-60 flex-col items-center justify-between"
          >
            {usercred && !userprofile && (
              <UserCredentials
                formData={formData}
                handleChange={handleChange}
              />
            )}
            {userprofile && !usercred && (
              <UserProfile
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                reportToUsers={reportToUsers}
                parameters={parameters}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            )}
            {!usercred && (
              <Button
                type="submit"
                disabled={loading}
                className="absolute bottom-4 right-2 flex items-center gap-1 bg-blue-700"
              >
                {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                <span>Submit</span>
              </Button>
            )}
          </form>
          <div>
            {submitError && <p className="text-red-500">{submitError}</p>}
          </div>
          <div className="flex w-80 justify-between">
            {!usercred && (
              <Button
                className="absolute bottom-4 left-2 flex items-center gap-1"
                onClick={() => {
                  setUserCred(true);
                  setUserProfile(false);
                }}
              >
                <MoveLeft />
                <span>Back</span>
              </Button>
            )}
            {!userprofile && (
              <Button
                className="absolute bottom-4 right-2 flex items-center gap-1"
                onClick={() => {
                  setUserCred(false);
                  setUserProfile(true);
                }}
              >
                <span>Next</span> <MoveRight />
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateUser;
