import React, { useEffect, useState, useContext } from 'react';
import { GoogleMap, LoadScript, Polyline, Marker } from '@react-google-maps/api';
import { AuthContext } from '../../contexts/AuthContext.jsx';
import { useParams } from 'react-router-dom';

const TrackingPath = () => {
  const { backendUrl } = useContext(AuthContext);
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(true);
  const { date, employeeId } = useParams();

  useEffect(() => {
    fetchEmployeePath();
  }, [date, employeeId, backendUrl]);

  const fetchEmployeePath = async () => {
    try {
      const response = await fetch(`${backendUrl}/track/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ date, id: employeeId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Fetched locations:', data);

      if (data && data.length > 0) {
        const fetchedCoords = data.map(coord => ({
          lat: parseFloat(coord.latitude),
          lng: parseFloat(coord.longitude),
        }));
        setCoordinates(fetchedCoords);
        console.log('Coordinates set:', fetchedCoords);
      } else {
        console.log('No data received');
        setCoordinates([]);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoading(false);
    }
  };

  const mapStyles = {
    height: '500px',
    width: '100%',
  };

  const defaultCenter = coordinates.length > 0 ? coordinates[0] : { lat: 0, lng: 0 };
  // const defaultCenter = {
  //   lat: 40.712776, // Default latitude (New York City)
  //   lng: -74.005974 // Default longitude (New York City)
  // }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <LoadScript googleMapsApiKey="AIzaSyCr1WudAampLCZiWTEd7jYPexiEqPzGf9Y">
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={defaultCenter}
          >
            {coordinates.length > 0 && (
              <>
                <Polyline path={coordinates} options={{ strokeColor: 'blue', strokeWeight: 8 }} />
                {coordinates.map((coord, index) => (
                  <Marker key={index} position={coord} />
                ))}
              </>
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  );
};

export default TrackingPath;
