import React, { useEffect, useState ,useContext} from "react";
import { api } from "../../lib/axios";
import { Button } from "../ui/Button";
import { Card } from "../ui/Card";
import { ScrollArea } from "../ui/ScrollArea";
import ApprovalList from "./ApprovalList";
import HrDashboard from "../Dashboard/HRDashboard";
import { AuthContext } from "../../contexts/AuthContext";

const SalesHeadApprovals = () => {
  const [approvalList, setApprovalList] = useState([]);
  const {backendUrl}=useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchApprovalList();
  }, []);

  const fetchApprovalList = async () => {
    try {
      setLoading(true);
      
      const response = await fetch(`${backendUrl}/get-user-approvals/`,{
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
       
      });
      if (response.status === 200) {
        const data=await response.json();
        setApprovalList(data);
      setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <HrDashboard>
      {approvalList?.length > 0 ? (
        <div className="flex  h-screen ">
          <div className="w-[90%]">
            <ScrollArea>
              <Card className="border-none border-0 w-[100%]  mx-auto p-4">
                <div className="flex flex-col space-y-4">
                  {approvalList.map((request, idx) => (
                    <ApprovalList
                      key={idx}
                      request={request}
                      fetchApprovalList={fetchApprovalList}
                    />
                  ))}
                </div>
              </Card>
            </ScrollArea>
          </div>
        </div>
      ) : (
        <div>
          <h1>No Approval Requests</h1>
        </div>
      )}
    </HrDashboard>
  );
};

export default SalesHeadApprovals;
