import { createContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

const backendUrl = "https://backend.gafieldsync.com/api/v1";

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchTokenValidation = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            backendUrl + "/auth/verifytoken/" + token,
          );
          setIsAuthenticated(response.data);
        } catch (error) {
          console.log(error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    fetchTokenValidation();
  }, []);

  const login = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
    sessionStorage.setItem("token", token);

  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
    sessionStorage.removeItem("token")
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        token,
        backendUrl,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
// export default AuthProvider;
