import React from "react";
import { Navigate } from "react-router-dom";

const CreateUserPage = React.lazy(() => import("./CreateUserPage"));
const Approvals = React.lazy(
  () => import("../components/Approval/SalesHeadApprovals")
);

const Dashboard = React.lazy(() => import("./Dashboard"));
const Workplan = React.lazy(() => import("../components/WorkPlan/WorkPlan"));
const LocationMap=React.lazy(() => import("../components/LocationMap"));
const Attendance = React.lazy(
  () => import("../components/Attendance/Attendance")
);
const Salesstructure = React.lazy(() => import("./SalesStructurePage"));
const Assign = React.lazy(
  () => import("../components/Assigngoals/Assigngoals")
);
const WorkplanApproval = React.lazy(
  () => import("../components/WorkPlan/WorkPlanApproval")
);
const ScheduleWork = React.lazy(
  () => import("../components/WorkPlan/ScheduleTasks")
);

const Schedules = React.lazy(() => import("../components/WorkPlan/Schedule"));
const TrackPath = React.lazy(
  () => import("../components/TrackingPath/ExecutivesList")
);
export const routes = [
  {
    path: "/create-user",
    exact: true,
    name: "Create-user",
    element: CreateUserPage,
    hrHeadOnly: false,
  },
  {
    path: "/approve-logins",
    exact: true,
    name: "Approvals",
    element: Approvals,
    hrHeadOnly: true,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    element: Dashboard,
    hrHeadOnly: false,
  },
  {
    path: "work-plan",
    exact: true,
    name: "Workplan",
    element: Workplan,
    hrHeadOnly: false,
  },
  {
    path: "/attendance",
    exact: true,
    name: "Attendance",
    element: Attendance,
    hrHeadOnly: false,
  },
  {
    path: "/define-sales-structure",
    exact: true,
    name: "Salesstructure",
    element: Salesstructure,
    hrHeadOnly: true,
  },
  {
    path: "/define-goals",
    exact: true,
    name: "Assign",
    element: Assign,
    hrHeadOnly: false,
  },
  {
    path: "/approve-plans",
    exact: true,
    name: "WorkplanApproval",
    element: WorkplanApproval,
    hrHeadOnly: false,
  },
  {
    path: "/schedule-tasks/",
    exact: true,
    name: "Schedules",
    element: Schedules,
    hrHeadOnly: false,
  },
  {
    path: "/schedule-tasks/:id",
    exact: true,
    name: "ScheduleWork",
    element: ScheduleWork,
    hrHeadOnly: false,
  },
  {
    path: "/tracking",
    exact: true,
    name: "TrackPath",
    element: TrackPath,
    hrHeadOnly: false,
  },
  {
    path: "/locationmap",
    exact: true,
    name: "LocationMap",
    element: LocationMap,
    hrHeadOnly: false,
  },
];
