import React, { useEffect, useState } from 'react';
import { api } from '../../lib/axios';
import { Button } from 'antd';
import './UserList.css'; // Import the CSS file
import HrDashboard from '../Dashboard/HRDashboard';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/users/');
                setUsers(response.data);
                console.log(response.data);
            } catch (err) {
                setError('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleDisable = async (userId) => {
        try {
            await api.post('/users/disable/', { user_id: userId });
            setUsers(users.map(user => user.id === userId ? { ...user, is_active: false } : user));
        } catch (err) {
            setError('Failed to disable user');
        }
    };

    const handleEnable = async (userId) => {
        try {
            await api.post('/users/enable/', { user_id: userId });
            setUsers(users.map(user => user.id === userId ? { ...user, is_active: true } : user));
        } catch (err) {
            setError('Failed to enable user');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <HrDashboard>
            <div className="user-list-container">
                <h1 className="user-list-header">User List</h1>
                <ul className="user-list">
                    {users.map(user => (
                        <li key={user.id} className="user-list-item">
                            <span className="user-username">{user.username}</span>
                            <div className="user-status">
                                {user.is_active ? (
                                    <Button onClick={() => handleDisable(user.id)}>Disable</Button>
                                ) : (
                                    <Button onClick={() => handleEnable(user.id)}>Enable</Button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </HrDashboard>
    );
};

export default UserList;
