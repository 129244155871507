import React from 'react';
import { useNavigate ,Link} from 'react-router-dom';
import { Button } from '../ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import  { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import './HRDashboard.css';
import {  Layout, Menu, theme } from 'antd';
const { Header, Sider, Content } = Layout;

const HrDashboard = ({children}) => {
  const [key,selectedKey]=useState('1');
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div className="p-6 flex justify-center ">
 <div className="demo-logo-vertical" />

    <Layout class="nav-layout">
  
       
        <Menu
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={[key]}
          items={[
            {
              key: '1',
              icon: <VideoCameraOutlined />,
              label: <Link to ="/define-sales-structure">Define Sales Structure and Roles </Link>
            },
            {
              key: '2',
              icon: <UserOutlined />,
              label: <Link to="/create-login">Create Login for Sales Head</Link>,
            },

            {
              key: '3',
              icon: <UploadOutlined />,
              label: <Link to ="/define-jd-kra">Define Job Description and KRAS</Link>
            },
           
            {
              key: '4',
              icon: <UploadOutlined />,
              label: <Link to ="/approve-logins">Approve Sales Head Logins</Link>
            },
            {
              key: '5',
              icon: <UploadOutlined />,
              label: <Link to ="/disable">Disable Users</Link>
            },
            // {
            //   key: '4',
            //   icon: <UploadOutlined />,
            //   label: <Link to ="/expense-reimbursement">Expense Reimbursement</Link>
            // },
          ]}
        />
     
      
    </Layout>
    <div className="layout">{children}</div>
          

    </div>
  );
};

export default HrDashboard;
