import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
// import PrivateRoute, { AuthProvider } from "../contexts/AuthContext";
import { routes } from "./routes";

const PrivatePageRouter = () => {
  const role = localStorage.getItem("role");
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route, idx) =>
          route.hrHeadOnly && role !== "HR_ADMIN" ? (
            <Route
              key={idx}
              exact={route.exact}
              path={route.path}
              name={route.name}
              element={<div>Not Found</div>}
            />
          ) : (
            <Route
              key={idx}
              exact={route.exact}
              path={route.path}
              name={route.name}
              element={<route.element role={role} />}
            />
          ),
        )}
      </Routes>
    </Suspense>
  );
};

export default PrivatePageRouter;
