import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext.jsx';
import { api } from '../../lib/axios';
import './EmployeeList.css'; // Import the CSS file for styling

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [employeeDates, setEmployeeDates] = useState([]);
  const { backendUrl } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await api.get(`${backendUrl}/fetchallemployees`);
      setEmployees(response.data.employees);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    navigate(`/track/${date}/${selectedEmployee}`);
  };

  const handleEmployeeClick = async (employeeId) => {
    setSelectedEmployee(employeeId);

    try {
      const response = await fetch(`${backendUrl}/fetchemployeeslocation?employee_id=${employeeId}`);
      const data = await response.json();
      setEmployeeDates(data);
    } catch (error) {
      console.error('Error fetching employee locations:', error);
    }
  };

  return (
    <div className="employee-list-container">
      <h1 className="main-title">Employee List</h1>
      <div className="employee-list">
        <ul className="employee-ul">
          {employees.map((employee) => (
            <li
              key={employee.employee_id}
              onClick={() => handleEmployeeClick(employee.employee_id)}
              className="employee-item"
            >
              {employee.user.name} - {employee.designation} - {employee.department}
            </li>
          ))}
        </ul>
      </div>

      {selectedEmployee && (
        <div className="dates-section">
          <h2 className="section-title">Available Dates for Employee {selectedEmployee}</h2>
          <ul className="dates-ul">
            {employeeDates.map((date, index) => (
              <li key={index} onClick={() => handleDateClick(date)} className="date-item">
                {date}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EmployeeList;
