import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignupPage from "./pages/CreateUserPage";
import LoginPage from "./pages/LoginPage";
import PrivatePageRouter from "./pages/PrivatePageRouter";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./contexts/PrivateRoute";
import TrackingPath from "./components/TrackingPath/TrackingPath";
import HrDashboard from "./components/Dashboard/HRDashboard";
import AddJD from "./components/CreateUser/AddJD";
import SalesHeadApprovals from "./components/Approval/SalesHeadApprovals";
import MyWorkPlans from "./components/WorkPlan/MyWorkPlans";
import EmployeeList from "./components/Dashboard/TrackEmployees";
import EmployeeMeetings from "./components/WorkReports/WorkReports"
import WorkReports from "./components/Dashboard/WorkReports";
import FetchMyInfo from "./components/Dashboard/MyInfo";
import UserList from "./components/CreateUser/DisableUser";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/create-login" element={<SignupPage />} />
          <Route exact path="/track/:date/:employeeId" element={<TrackingPath />} />
          <Route exact path="/define-jd-kra" element={<AddJD />} />
          <Route exact path="/myworkplans" element={<MyWorkPlans />} />
          <Route exact path="/trackall" element={<EmployeeList/>} />
          <Route exact path="/workreports" element={<EmployeeMeetings/>} />
          <Route exact path="/myinfo" element={<FetchMyInfo/>} />
          <Route exact path="/disable" element={<UserList/>} />
          

          <Route exact path="/approve-workreports" element={<WorkReports/>} />

          {/* <Route exact path="/approve-logins" element={<SalesHeadApprovals/>} /> */}

          <Route
            exact
            path="/*"
            element={
              <PrivateRoute>
                <PrivatePageRouter />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
