import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api } from '../../lib/axios';
import EmployeeDashboard from '../Dashboard/EmployeeDashboard';

const EmployeeMeetings = () => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [comment,setComment]=useState(null);
  useEffect(() => {
    // Fetch meetings from the API
    
    fetchMeetings();
  }, []);
  const fetchMeetings = async () => {
    try {
      const response = await api.get('/employee/meetings');
      setMeetings(response.data.data);
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };
  const fetchComments= async (date) => {
    try {
      const response = await api.post('/fetchcomments/',{'date':date});
      setComment(response.data.data);
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };







  const renderMeetingDetails = (meetings) => {
    return (
      <EmployeeDashboard>
      <div>
        {meetings && meetings.map((meeting, index) => (
          <div key={index} style={styles.meetingDetails}>
            <p><strong>Client Name:</strong> {meeting.client.name}</p>
            <p><strong>Client Phone Number:</strong> {meeting.client.phone}</p>
            <p><strong>Organization Name:</strong> {meeting.client.organization_name}</p>
            <p><strong>Location:</strong> {meeting.client.address}</p>
            <p><strong>Status:</strong> {meeting.status}</p>
            <p><strong>Summary of the Meeting:</strong> {meeting.summary}</p>
          </div>
        ))}
      </div>
      {comment && <div>Comment on the work report : {comment.comments}</div>}
      </EmployeeDashboard>
    );
  };
  const handleSelection=(meetings,date)=>{
    setSelectedMeeting(meetings);
    fetchComments(date)
  }

  const renderScheduleDates = (item) => (
    <button onClick={()=>handleSelection(item.meetings,item.date)} style={styles.scheduleDate}>
      {item.date}
    </button>
  );

  return (
    <EmployeeDashboard>
    <div style={styles.container}>
      <h1 style={styles.header}>Employee Meetings</h1>
      {selectedMeeting ? (
        <div>
          <button onClick={() => {setSelectedMeeting(null);setComment(null)}} style={styles.backButton}>← Back to schedule</button>
          {renderMeetingDetails(selectedMeeting)}
        </div>
      ) : (
        <div>
          {meetings.map((item) => (
            <div key={item.id}>
              {renderScheduleDates(item)}
            </div>
          ))}
        </div>
      )}
    </div>
    </EmployeeDashboard>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  scheduleDate: {
    fontSize: '16px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    margin: '5px 0',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
  },
  meetingDetails: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '5px',
    marginTop: '20px',
  },
  backButton: {
    color: 'blue',
    margin: '10px 0',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
  },
};

export default EmployeeMeetings;
