import React ,{useEffect,useState,useContext}from 'react'
import {Table,Button ,message,Modal, Select,Form} from "antd";
import { AuthContext } from '../../contexts/AuthContext'
import TextArea from 'antd/es/input/TextArea';
import { Label } from '@radix-ui/react-label';
import EmployeeDashboard from '../Dashboard/EmployeeDashboard';

const MyWorkPlans =() => {
    const {backendUrl}=useContext(AuthContext);
    const [data,setData]=useState([]);
    const [record,setRecord]=useState();
    const [reason,setReason]=useState();
    const [visible,setModalVisible]=useState(false)
    const [status,setStatus]=useState()
    useEffect(()=>{
        fetchWorkPlans();

    },[])
    const setModal=(value,id)=>{
        setModalVisible(value)
        setRecord(id)
    }
    const handleStatusChange=(e)=>{
        console.log(e)
        setStatus(e);
        if(e =="Canceled"){
            var r=document.getElementsByClassName('textarea');
            console.log(r)
            r[0].style.display="block";

        }
        else{
            var r1=document.getElementsByClassName('textarea');
            r1[0].style.display="none";
            setReason("");
        }

    }
    const handleReasonChange=(e)=>{
        setReason(e)

    }

    const handleStatusUpdate=async()=>{

         const response=await fetch(`${backendUrl}/fetch-workplans/`,{
            method:"POST",
        headers:{
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

        },
        body: JSON.stringify({id:record,status:status,reason:reason})

    });
        if(response.ok){
            const data=await response.json();
            message.success(data.success)
            setModal(false);
            setReason("");
            const r=document.getElementsByClassName('textarea');
            r[0].style.display="none";
            setStatus(null);
            fetchWorkPlans();
        }
   

    }
    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Clients',
          dataIndex: 'clients',
          key: 'clients',
          render: (clients) => (
            clients.map(client => (
              <div key={client.id}>
                <strong>Name:</strong> {client.name} <br />
                <strong>Address:</strong> {client.address} <br />
                <strong>Phone:</strong> {client.phone} <br />
                <br />
              </div>
            ))
          ),
        },
       
        
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
       
        {
            title: 'Action Plan',
            dataIndex: 'action_plan',
            key: 'action_plan',
            render: (action_plan) => (action_plan ? action_plan : 'N/A'),
          },
          {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (reason) => (reason ? reason : 'N/A'),
          },
        {
          title: 'Approved',
          dataIndex: 'approved',
          key: 'approved',
          render: (approved) => (approved ? 'Yes' : 'No'),
        },
      ];
      
    


    const fetchWorkPlans=async()=>{
    const response=await fetch(`${backendUrl}/fetch-workplans/`,{
        headers:{
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    }
)

    if (response.ok){
        const data=await response.json()
        setData(data.data)

    }
}



  return (
    <EmployeeDashboard>
    <div>MyWorkPlans</div>
    <Table dataSource={data} columns={columns}></Table>
    <Modal
    open={visible}
    onCancel={() => setModalVisible(false)}
    onOk={handleStatusUpdate}
    title='Update Status'>
       
       <Form>
        <Form.Item>
        <Select name="Select Status" onChange={handleStatusChange}>
            <Select.Option value='Attended'>Attended</Select.Option>
            <Select.Option value='Canceled'>Cancelled</Select.Option>
            <Select.Option value='Rescheduled'>Rescheduled</Select.Option>
        </Select>
        </Form.Item>

        <div className="textarea" style={{"display":"none"}}>
        <Form.Item  >
            <Label>Enter the reason for cancellation</Label>
            <TextArea onChange={handleReasonChange}></TextArea>
        </Form.Item>
        </div>
        </Form>
       
        

    </Modal>
    </EmployeeDashboard>
  )
}

export default MyWorkPlans