import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FetchMyInfo.css';  // Make sure to create and include the CSS file
import { api } from '../../lib/axios';

const FetchMyInfo = () => {
  const [data, setData] = useState({
    role: {},
    primarygoals: [],
    secondarygoals: [],
    einfo: {},
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/fetch-my-info/');
        console.log('API Response:', response.data);  // Log API response
        setData({
          role: response.data.role || {},
          primarygoals: Array.isArray(response.data.primarygoals) ? response.data.primarygoals : [],
          secondarygoals: Array.isArray(response.data.secondarygoals) ? response.data.secondarygoals : [],
          einfo: response.data.einfo || {},
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  const { role, primarygoals, secondarygoals, einfo } = data;

  return (
    <div className="container">
      <div className="section">
        <h2 className="header">Role</h2>
        <p className="text">{role.name || 'No role information'}</p>
        <p className="text">{role.description || 'No role description'}</p>
      </div>

      <div className="section">
        <h2 className="header">Employee Info</h2>
        <p className="text">JD: {einfo.jd || 'No JD information'}</p>
        <p className="text">KRAs: {einfo.kras || 'No KRAs information'}</p>
      </div>

      <div className="section">
        <h2 className="header">Primary Goals</h2>
        {primarygoals.length > 0 ? (
          primarygoals.map((goal, index) => (
            <div key={index} className="goal-container">
              <p className="goal-text">Date: {goal.parameter_name || 'No date information'}</p>
              <p className="goal-text">Monthly: {goal.monthly_goal || 'No monthly goal'}</p>
              <p className="goal-text">Quarterly: {goal.quaterly_goal || 'No quarterly goal'}</p>
              <p className="goal-text">Half-Yearly: {goal.half_yearly_goal || 'No half-yearly goal'}</p>
              <p className="goal-text">Annual: {goal.annual_goal || 'No annual goal'}</p>
              <h1>My Reached Target</h1>

              <p> Monthly-goal{goal.upadated_monthly_goal || 'No monthly goal'}</p>
              <p>Quarterly: {goal.upadated_quarterly_goal || 'No quarterly goal'}</p>
              <p>Half-Yearly: {goal.upadated_half_yearly_goal || 'No half-yearly goal'}</p>
              <p>Annual: {goal.upadated_yearly_goal || 'No annual goal'}</p>

            </div>
          ))
        ) : (
          <div className="no-data">No primary goals available</div>
        )}
      </div>

      <div className="section">
        <h2 className="header">Secondary Goals</h2>
        {secondarygoals.length > 0 ? (
          secondarygoals.map((goal, index) => (
            <div key={index} className="goal-container">
              <p className="goal-text">Date: {goal.parameter_name || 'No date information'}</p>
              <p className="goal-text">Monthly: {goal.monthly_goal || 'No monthly goal'}</p>
              <p className="goal-text">Quarterly: {goal.quaterly_goal || 'No quarterly goal'}</p>
              <p className="goal-text">Half-Yearly: {goal.half_yearly_goal || 'No half-yearly goal'}</p>
              <p className="goal-text">Annual: {goal.annual_goal || 'No annual goal'}</p>
              <h1>My Reached Target</h1>

              <p> Monthly-goal{goal.upadated_monthly_goal || 'No monthly goal'}</p>
              <p>Quarterly: {goal.upadated_quarterly_goal || 'No quarterly goal'}</p>
              <p>Half-Yearly: {goal.upadated_half_yearly_goal || 'No half-yearly goal'}</p>
              <p>Annual: {goal.upadated_yearly_goal || 'No annual goal'}</p>
              
            </div>
          ))
        ) : (
          <div className="no-data">No secondary goals available</div>
        )}
      </div>
    </div>
  );
};

export default FetchMyInfo;
