import React from "react";
import { Input } from "../ui/Input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import AssignGoals from "../Assigngoals/Assigngoals";

const UserProfile = ({
  formData,
  setFormData,
  handleChange,
  reportToUsers,
  parameters,
  formValues,
  setFormValues,
}) => {
  const genderOptions = [
    { value: "M", name: "Male" },
    { value: "F", name: "Female" },
  ];
  const departmentOptions = [
    { value: "sales", name: "Sales Dept" },
    { value: "marketing", name: "Marketing Dept" },
    { value: "finance", name: "Finance Dept" },
    { value: "hr", name: "Hr Dept" },
    { value: "it", name: "IT Dept" },
    { value: "support", name: "Support Dept" },
  ];
  const typeOptions = [
    { value: "full-time", name: "Full Time" },
    { value: "part-time", name: "Part Time" },
    { value: "contract", name: "Contract" },
    { value: "internship", name: "Internship" },
    { value: "freelance", name: "Freelance" },
    { value: "remote", name: "Remote" },
  ];

  const designationOptions = [
    { value: "manager", name: "Manager" },
    { value: "asst. Manager", name: "Asst. Manager" },
    { value: "hr", name: "HR" },
    { value: "asst. HR", name: "Asst. HR" },
  ];

  const rolesOptions = [
    { value: "HR_ADMIN", name: "HR Admin" },
    { value: "Sales Head", name: "Sales Head" },
    { value: "Manager - Sales", name: "Manager - Sales" },
    { value: "Asst. Manager Sales", name: "Asst. Manager Sales" },
    { value: "Team Lead", name: "Team Lead" },
    { value: "Sr. Sales Executive", name: "Sr. Sales Executive" },
    { value: "Sales Executive", name: "Sales Executive" },
  ];
  return (
    <div className="flex flex-col items-center justify-center gap-2 md:gap-5 lg:w-[65svw]">
      <div className="lg:gap-15 flex flex-col flex-wrap justify-center gap-2 md:flex-row md:gap-5">
        <Input
          className="w-48"
          type="text"
          placeholder="Enter Employee ID"
          id="employee_id"
          onChange={handleChange}
        />
        <SelectComp
          items={reportToUsers.roles}
          name="designation"
          setFormData={setFormData}
        />
        <SelectComp
          items={departmentOptions}
          name="department"
          setFormData={setFormData}
        />
        <SelectComp items={typeOptions} name="type" setFormData={setFormData} />

        <SelectComp
          items={genderOptions}
          name="gender"
          setFormData={setFormData}
        />
        <Input
          className="w-48"
          type="tel"
          placeholder="Enter Mobile Number"
          id="phone_number"
          maxLength={10}
          onChange={handleChange}
        />
        <Input
          type="date"
          className="w-48"
          placeholder="Enter Date of Joining"
          id="date_of_joining"
          onChange={handleChange}
        />

        <Input
          type="number"
          className="w-48"
          placeholder="Enter CTC"
          id="ctc"
          onChange={handleChange}
        />
        <SelectComp
          items={reportToUsers.users}
          name="reported_to"
          setFormData={setFormData}
        />
        <SelectComp
          items={reportToUsers.roles}
          name="role"
          setFormData={setFormData}
        />
      </div>
      <AssignGoals
        parameters={parameters}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    </div>
  );
};

export const SelectComp = ({ items, name, setFormData }) => {
  return (
    <Select
      onValueChange={(value) =>
        setFormData((prev) => ({ ...prev, [name]: value }))
      }
    >
      <SelectTrigger className="w-48 p-1">
        <SelectValue placeholder={`${name}`} />
      </SelectTrigger>

      <SelectContent>
        <SelectGroup>
          {items.map((item, idx) => (
            <SelectItem key={idx} value={`${item.value}`}>
              {item.role ? `${item.role} - ` : null}
              {item.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
export default UserProfile;
