import React, { useEffect, useState } from 'react';
import { api } from '../../lib/axios';
import EmployeeDashboard from './EmployeeDashboard';
import { message } from 'antd';

const EmployeeMeetings = () => {
  const [employees, setEmployees] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [workReport, setWorkReport] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await api.get('/employees');
      setEmployees(response.data.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const fetchWorkReports = async (date) => {
    try {
      const response = await api.post(`/fetch-work-reports/`, { 'employeeId': selectedEmployee, 'date': date });
      setWorkReport(response.data.data);
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };

  const handleEmployeeSelect = async (employeeId) => {
    try {
      const response = await api.get(`/fetch-all-employee-meetings/?employeeId=${employeeId}`);
      setMeetings(response.data.data);
      setSelectedEmployee(employeeId);
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };

  const handleAddComment = async () => {
    try {
      const response = await api.post('/add-comment/', { 'id': selectedEmployee, 'date': selectedDate, 'comment': comment });
      setComment('');
      message.success(response.data.success);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const renderMeetingDetails = (meetings) => (
   
      <div>
        {meetings && meetings.map((meeting, index) => (
          <div key={index} style={styles.meetingDetails}>
            <p><strong>Client Name:</strong> {meeting.client.name}</p>
            <p><strong>Client Phone Number:</strong> {meeting.client.phone}</p>
            <p><strong>Organization Name:</strong> {meeting.client.organization_name}</p>
            <p><strong>Location:</strong> {meeting.client.address}</p>
            <p><strong>Status:</strong> {meeting.status}</p>
            <p><strong>Summary of the Meeting:</strong> {meeting.summary}</p>
          </div>
        ))}

        {!workReport && (
          <div>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add a comment"
              style={styles.commentBox}
            />
            <button onClick={() => handleAddComment()} style={styles.addButton}>Add Comment</button>
          </div>
        )}
      </div>
   
  );

  const renderScheduleDates = (item) => (
    <button
      onClick={() => {
        setSelectedMeeting(item.meetings);
        setSelectedDate(item.date);
        fetchWorkReports(item.date);
      }}
      style={styles.scheduleDate}
    >
      {item.date}
    </button>
  );

  return (
   
      <div style={styles.container}>
        <h1 style={styles.header}>Employee Meetings</h1>
        {selectedEmployee === null ? (
          <div>
            <h2>Select an Employee</h2>
            {employees.map((employee) => (
              <button
                key={employee.id}
                onClick={() => handleEmployeeSelect(employee.employee_id)}
                style={styles.employeeButton}
              >
                {employee.username}
              </button>
            ))}
          </div>
        ) : selectedMeeting ? (
          <div>
            <button onClick={() => { setSelectedMeeting(null); setSelectedDate(null); setWorkReport(false); }} style={styles.backButton}>← Back to schedule</button>
            {renderMeetingDetails(selectedMeeting)}
          </div>
        ) : (
          <div>
            <button onClick={() => setSelectedEmployee(null)} style={styles.backButton}>← Back to employees</button>
            {meetings.map((item) => (
              <div key={item.id}>
                {renderScheduleDates(item)}
              </div>
            ))}
          </div>
        )}
      </div>
    
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  employeeButton: {
    fontSize: '16px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    margin: '5px 0',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
  },
  scheduleDate: {
    fontSize: '16px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    margin: '5px 0',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
  },
  meetingDetails: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '5px',
    marginTop: '20px',
  },
  commentBox: {
    width: '100%',
    padding: '10px',
    marginTop: '10px',
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  backButton: {
    color: 'blue',
    margin: '10px 0',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
  },
};

export default EmployeeMeetings;
