import React from "react";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";

const UserCredentials = ({ formData, handleChange }) => {
  return (
    <div className="flex w-[80%] flex-col gap-8">
      <div>
        <Label htmlFor="username">Username</Label>
        <Input
          type="text"
          placeholder="Enter Username"
          id="username"
          value={formData.username}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          placeholder="Enter Email"
          id="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="password">Password</Label>
        <Input
          type="password"
          placeholder="Enter Password"
          id="password"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default UserCredentials;
