import React from "react";
import CreateUser from "../components/CreateUser/CreateUser";
import HrDashboard from "../components/Dashboard/HRDashboard";

const CreateUserPage = () => {
  return (

  <HrDashboard>
   <CreateUser /></HrDashboard>);
};

export default CreateUserPage;
