import axios from "axios";
import Cookie from "js-cookie";

const apiUrl = "https://backend.gafieldsync.com/api/v1/";
// const apiUrl = "http://192.168.1.16:8000/api/v1/";

const token = localStorage.getItem("token");
const csrf_token = Cookie.get("csrftoken");
export const api = axios.create({
  baseURL: apiUrl,
  // withCredentials: true,
  headers: {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
    "x-csrftoken": csrf_token,
  },
});
