import React, { useState } from "react";
import { Button } from "../ui/Button";
import { CardContent } from "../ui/Card";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";

const AssignGoals = ({ parameters, formValues, setFormValues }) => {
  const periods = ["Monthly", "Quarterly", "Half_Yearly", "Annually"];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [prefix, period, paramName] = name.split(".");
    const newFormValues = { ...formValues };
    // console.log(formValues[prefix][paramName][period.toLowerCase()]);
    console.log(value);
    newFormValues[prefix][paramName][period.toLowerCase()] = value;

    setFormValues(newFormValues);
  };
  const renderTable = (title, params, prefix) => (
    <>
      <Label className="mb-2 text-xl font-semibold">{title}</Label>
      <table className="mb-4 min-w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2 text-left">Parameters</th>
            {periods.map((period, idx) => (
              <th key={idx} className="border border-gray-300 p-2 text-left">
                {period} Goal
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {params.map((param, idx) => (
            <tr key={idx}>
              <td className="border border-gray-300 p-2">{param.name}</td>
              {periods.map((period, neidx) => (
                <td key={neidx} className="border border-gray-300 p-2">
                  <Input
                    name={`${prefix}.${period}.${param.name}`}
                    value={
                      formValues[prefix][param.name][period.toLowerCase()] || ""
                    }
                    onChange={handleInputChange}
                    placeholder={` Enter ${period.toLowerCase()} goal`}
                    className="w-full rounded border border-gray-300 p-2"
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      {renderTable("Primary Goals", parameters.primary, "primary")}
      {renderTable("Secondary Goals", parameters.secondary, "secondary")}
    </>
  );
};

export default AssignGoals;
