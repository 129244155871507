import React from "react";
import { Button } from "../ui/Button";
import { api } from "../../lib/axios";

const ApprovalList = React.memo(({ request, fetchApprovalList }) => {
  const [loading, setLoading] = React.useState(false);
  const handleApprove = async (id) => {
    setLoading(true);
    try {
      const response = await api.post("/user/approve", { employee_id: id });
      console.log(response);
      await fetchApprovalList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleReject = async (id) => {
    setLoading(true);
    try {
      const response = await api.post("/user/reject", { employee_id: id });
      console.log(response);
      await fetchApprovalList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  return (
    <div className="x">
      <div className="y">
        <h3 className="text-lg font-bold text-gray-700">
          {request.employee_name}
        </h3>
        <p className="text-gray-600">{request.designation}</p>
        <p
          className={`font-semibold ${request.status === "Pending" ? "text-yellow-500" : request.status === "Approved" ? "text-green-500" : "text-red-500"}`}
        >
          {request.status}
        </p>
      </div>
      {request.status === "Pending" && (
        <div className="flex space-x-4">
          <Button
            disabled={loading}
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={() => handleApprove(request.employee)}
          >
            Approve
          </Button>
          <Button
            disabled={loading}
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={() => handleReject(request.employee)}
          >
            Reject
          </Button>
        </div>
      )}
    </div>
  );
});

export default ApprovalList;
