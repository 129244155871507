import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Link} from 'react-router-dom';
import {  Layout, Menu, theme } from 'antd';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
const EmployeeDashboard = ({children}) => {
    const navigate = useNavigate();

  return (
    <div className="p-6 flex justify-center">
<div className="p-6 flex justify-center ">
 <div className="demo-logo-vertical" />

    <Layout class="nav-layout">
  
       
        <Menu
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={[key]}
          items={[
            // {
            //   key: '1',
            //   icon: <UserOutlined />,
            //   label: <Link to="/attendance">Attendance</Link>,
            //   // render:selectedKey('2')
            // },
            {
              key: '1',
              icon: <VideoCameraOutlined />,
              label: <Link to ="/work-plan">Work Plan</Link>
            },
            {
              key: '2',
              icon: <UploadOutlined />,
              label: <Link to ="/schedule-tasks">Schedule Tasks</Link>
            },
            {
              key: '3',
              icon: <UploadOutlined />,
              label: <Link to ="/workreports">Work Report</Link>
            },
            {
              key: '4',
              icon: <UploadOutlined />,
              label: <Link to ="/myworkplans">My Work Plans</Link>
            },
            {
              key: '5',
              icon: <UploadOutlined />,
              label: <Link to ="/myinfo">My Information</Link>
            },
          ]}
        />
     
      
    </Layout>
    <div className="layout">{children}</div>
          

    </div>

      {/* <Card>
        <CardHeader>
          <CardTitle>Employee Dashboard</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex justify-center space-x-4">
            <Button className="w-64" onClick={() => navigate('/attendance')}>Attendance</Button>
            <Button className="w-64" onClick={() => navigate('/work-plan')}>WorkPlan</Button>
            <Button className="w-64" onClick={() => navigate('/schedule-tasks')}>Schedules tasks</Button>
            <Button className="w-64" onClick={() => navigate('/work-report')}>Work Report</Button>
            <Button className="w-64" onClick={() => navigate('/myworkplans')}>My Work Plans</Button>
          </div>
        </CardContent>
      </Card> */}
    </div>
  );

};
export default EmployeeDashboard;