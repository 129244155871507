import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";

const Login = () => {
  const [email, setEmailState] = useState("");
  const [password, setPasswordState] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://backend.gafieldsync.com/api/v1/login/", {
        email,
        password,
      });
      const { token, role } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);

      navigate("/dashboard");
    } catch (error) {
      setError("Invalid email or password. Please try again.");
    }
  };

  const handleEmailChange = (event) => {
    setEmailState(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordState(event.target.value);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 ">
      <div className="flex flex-col justify-between items-center bg-white p-8  rounded-lg shadow-lg w-80 max-w-md">
        <Label className="text-3xl font-bold mb-6 text-gray-700">Login</Label>
        <form className="mb-4 w-full " onSubmit={handleLogin}>
          <Label className="text-gray-700 text-md">Email</Label>
          <Input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            required
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <Label className="text-gray-700 text-md">Password</Label>
          <Input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter your password"
            required
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <Input
            type="submit"
            value="Login"
            className="w-full py-2 flex justify-center bg-blue-500 text-white rounded hover:bg-blue-600"
          />
        </form>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
